@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    color: white;
    /* font-family: 'Lato', serif;     */
    width: 100%;
    height: 100%;
    background-color: white;
    
}


#logo-image {
    width: 100px;
}

.description-block{
    width:500px;
}

#ios-download-button, #androind-waitlist-button {
    text-align:center;
}

#access-button {
    background-color: #78FFFE;
}


@media only screen and (max-width:520px) {
    #logo-image {
        width:100px;
    }

    .description-block{
        width: 100%;
    }
}